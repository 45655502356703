<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="pt-80">
					<div class="intro_logo_title">
						<span class="hide">{{ $language.common.title }}</span>
					</div>
				</div>
				<div class="mt-50 intro_btn_wrap">
					<div class="social_login">

						<button
							class="btn_l btn_kakao"
							@click="getDeviceInfo('kakao')"
						>{{ $language.auth.login_with_kakoa }} </button>

						<button
							class="btn_l btn_google"
							@click="toGAuth"
						>{{ $language.auth.login_with_google }} </button>

						<button
							class="btn_l btn_apple"
							@click="getDeviceInfo('apple')"
						>{{ $language.auth.login_with_apple }} </button>

						<button
							v-if="true"
							class="btn_l btn_id"
							@click="toIdLogin('id')"
						>ID 로그인</button>

						<vue-apple-login
							v-show="false"

							:onSuccess="handleAppleLoginSuccess"
							:onFailure="handleAppleLoginFail"
						></vue-apple-login>
					</div>


					<div
						v-if="false"
					>
						<button
							type="primary"
							icon="fas fa-edit"
							@click="handleClickLogin"
							:disabled="!isInit"
						>get authCode</button>
						<button
							type="primary"
							icon="fas fa-edit"
							@click="handleClickSignIn"
							v-if="!isSignIn"
							:disabled="!isInit"
						>sign in</button>
						<button
							type="primary"
							icon="fas fa-edit"
							@click="handleClickSignOut"
							v-if="isSignIn"
							:disabled="!isInit"
						>sign out</button>
						<button
							type="primary"
							icon="fas fa-edit"
							@click="handleClickDisconnect"
							:disabled="!isInit"
						>disconnect</button>
						<i class="fas fa-edit"></i>
						<p>isInit: {{isInit}}</p>
						<p>isSignIn: {{isSignIn}}</p>

						<button
							type="primary"
							icon="fas fa-edit"
							@click="handleClickUpdateScope"
							:disabled="!isInit"
						>update scope</button>
					</div>

					<a @click="$emit('to', { name: 'mafia004'})" class="btn_signup">{{ $language.common.signUp }}</a>
					<p class="copyright">Copyright © MAFI Inc. </p>
				</div>
			</div>
		</div>

		<Popup_confirm
			v-if="is_update"
			:not_cancel="true"
			@click="is_update = false"
		>
			<template v-slot:title>업데이트 안내</template>
			<template
				v-slot:sub-txt
			>
				로그인 오류 발생시 스토어에서 앱 업데이트 후 다시 이용해주세요
			</template>
		</Popup_confirm>
	</div>
</template>

<script>

import {v4 as uuidvr4} from "uuid";
import Popup_confirm from "@/view/Layout/PopupConfirm";

export default {
	name: 'mafia011'
	, props: ['user']
	, components: {Popup_confirm }
	,data: function(){
		return {
			program: {
				name: this.$language.common.login
				, not_header: true
				, not_footer: true
			}
			,kakao_info: null
			,isInit: false
			,isSignIn: false
			, social_code: ''
			, state: '1234'
			,is_pin: false
			,pin_option: {
				pin_type: 'check'
			}
			, is_otp: false
			, is_auto: this.$route.params.auto
			, login_type: ''
			, is_update: false
		}
	}
	, computed: {
		kakao_return_url: function(){

			let domain = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let local = 'https://localhost:8080'
			let location = window.location.href
			let url = ''

			console.log('location', location)
			if(location.indexOf(domain) > -1){
				url = domain
			}else if(location.indexOf(dev) > -1){
				url = dev
			}else{
				url = local
			}

			return url + '/auth/loginkakao'
		}
	}
	,methods: {
		loginWith: async function(){

			let type = this.login_type
			console.log('loginWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_kakao')
				}catch(e){
					console.log('kakao login error', e)
					await this.handleSignInKakao()
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_google')
				}catch(e){
					console.log('google login error', e)
					await this.handleClickUpdateScope2()
				}
			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_apple')
				}catch(e){
					console.log('apple login error', e)
					document.getElementById('appleid-signin').click()
				}
			}else{
				await this.$router.push({ name: 'mafia0111'})
			}
		}
		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}

			if(this.$common.isMobile() == 'CM00500003'){

				if(this.$common.getMobile() == 'ios' || this.$common.getMobile() == 'android'){

					await this.kakaoLogin()
				}else{
					await this.kakaoLoginForm()

				}
			}else{
				await this.kakaoLoginForm()
			}
		}
		,async handleClickUpdateScope2() {
			await this.$gAuth.signOut();
			console.log('handleClickUpdateScope2')

			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();

			try {
				let result = await googleUser.grant(option);
				console.log("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					console.log("t re!!", t);
				}

				let r = {
					type : 'google'
					, access_token: t.access_token
				}

				await this.loginWithSocial(r);

				//await this.joinWithGoogle(googleUser.Cc.access_token, googleUser.Av.mw)
			} catch (error) {
				console.error(error);
			}
		}

		,kakaoAuthorize: async function(){
			window.Kakao.Auth.authorize({
				redirectUri: this.kakao_return_url
			});
		}

		, kakaoLogin: async function(){
			let self = this
			window.Kakao.Auth.login({
				success: function() {
					/*
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())
					 */
					let r = {
						type: 'kakao'
						, access_token: window.Kakao.Auth.getAccessToken()
					}

					self.loginWithSocial(r)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}

		,kakaoLoginForm: async function(){
			let self = this
			window.Kakao.Auth.loginForm({
				success: function() {
					/*
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())
					 */
					let r = {
						type: 'kakao'
						, access_token: window.Kakao.Auth.getAccessToken()
					}

					self.loginWithSocial(r)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}

		,  loginWithSocial: async function(e){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_login_social
					, data: {
						member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					// console.log('this.token_info', this.token_info)

					sessionStorage.removeItem('Error')
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}

					switch (result.data.member_state_code){
						default:
							this.$bus.$emit('to', {name: 'mafia009'})
							break;
						case 'MB00500001':
							this.$bus.$emit('to', {name: 'sms'})
							break;
						case 'MB00500002':
							this.$bus.$emit('onPin', 'join')
							break;
						case 'MB00500003':
							this.$bus.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500004':
							this.$bus.$emit('to', {name: 'nice'})
							break;
						case 'MB00500005':
							if(result.data.session_token){
								await this.$encodeStorage.setSessionToken(result.data.session_token)
							}
							await this.$encodeStorage.setItem('login_info', e)
							this.onPin('pin_login')
							//this.$bus.$emit('setUser', result.data)
							break;
						case 'MB00500006':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'MB00500007':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
							break;
						case 'MB00500008':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
					}
				}else{
					console.log(result)
					if((result.code).indexOf('E0014') > -1){
						await this.$encodeStorage.removeItem('login_info')
						this.$bus.$emit('to', { name: 'mafia009', params: {type: 'auth', msg: result.message}})
					}else {

						switch (result.code) {
							case 'E000500001':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'not', msg: result.message}})
								break;
							case 'E000400012':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								break;
							case 'E000400013':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								break;
							case 'E000400014':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								break;
							case 'E001400003':
							case 'E001400004':
							case 'E001400008':
								await this.$encodeStorage.removeItem('login_info')
								throw result.code
								// throw this.$language.auth.expired_auth_login
							default:
								await this.$encodeStorage.removeItem('login_info')
								throw result.message
						}
					}
				}
			}catch (e) {
				console.log(e)
				this.error = e

				// this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
				this.$emit('to', { name: '400', params: { type: e}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,async handleClickUpdateScope() {
			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
			try {
				await googleUser.grant(option);
				console.log(googleUser)
				console.log("success");
			} catch (error) {
				console.error(error);
			}
		},
		handleClickLogin() {
			this.$gAuth
				.getAuthCode()
				.then((authCode) => {
					//on success
					console.log("authCode", authCode);
				})
				.catch((error) => {
					console.log(error)
				});
		},
		async handleClickSignIn() {
			try {
				const googleUser = await this.$gAuth.signIn();
				if (!googleUser) {
					return null;
				}
				console.log("googleUser", googleUser);
				console.log("getId", googleUser.getId());
				console.log("getBasicProfile", googleUser.getBasicProfile());
				console.log("getAuthResponse", googleUser.getAuthResponse());
				console.log(
					"getAuthResponse",
					this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
				);
				this.isSignIn = this.$gAuth.isAuthorized;
			} catch (error) {
				//on fail do something
				console.error(error);
				return null;
			}
		},
		async handleClickSignOut() {
			try {
				await this.$gAuth.signOut();
				this.isSignIn = this.$gAuth.isAuthorized;
				console.log("isSignIn", this.$gAuth.isAuthorized);
			} catch (error) {
				console.error(error);
			}
		},
		handleClickDisconnect() {
			window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
		}
		, onOtp: function(){
			this.is_pin = false
			this.is_otp = true
		}
		, postOtp: async function(gogle_otp_certfc_number){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_otp
					, data: {
						gogle_otp_certfc_number: gogle_otp_certfc_number
					}
					, type: true
				})

				if(result.success){
					this.onPin('update')
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, onPin: function(pin_type){
			this.$bus.$emit('onPin', pin_type)
		}
		, offPin: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'check'
		}
		, handleAppleLoginSuccess: function(data){
			console.log('handleAppleLoginSuccess', data)
			this.social_code = 'MB00400002'
			let e = {
				type: 'apple'
				, access_token: data.authorization.code
			}
			this.loginWithSocial(e)
		}
		, handleAppleLoginFail: function(error){
			console.log(error)
			this.$bus.$emit('notify', { type: 'error', message: this.$language.auth.login_fail_apple})
		}

		, getDeviceInfo: async function(type){
			this.login_type = type
			console.log('in getDeviceInfo')
			// 디바이스 정보
			try{
				this.$common.inAppWebviewCommunity('android', 'device_info')
				console.log('in app webview')
				window.device_info = async (device_info) => {
					device_info = JSON.parse(device_info)
					console.log('window.device_info', device_info)

					//this.is_view = true

					await this.$encodeStorage.setSessionCertfc(device_info)
					await this.getToken(device_info)
					await this.$encodeStorage.setItem('_uuid', device_info.push_id)
				}
			}catch (e){
				console.log('디바이스 정보', e)

				// 디바이스 uuid
				let uuid = this.$encodeStorage.getItem('_uuid')

				if(!uuid){
					uuid = uuidvr4()
					await this.$encodeStorage.setItem('_uuid', uuid)
				}

				let device_info = {
					"conn_div_code": this.$common.isMobile(),
					"uniq_device_id": uuid,
					"mobile_opsys_code": this.$common.getOsCode(),
					"opsys_version": "",
					"push_id": uuid,
					"app_version": "1.0.12",
					"conn_ip": await this.$common.getIp()
				}
				await this.getToken(device_info)
			}
		}

		, getToken: async function(token){

			console.log('postDeviceToken')
			/*
			for(let [k, v] of Object.entries(token)){
				//alert(k + ':' + v)
				console.log(k, v)
			}

			 */
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.post_device_token
					, data: token
					, type: true
				})
				if (result.success) {

					this.item_app_info = result.data

					for(let [k, v] of Object.entries(this.item_app_info)){
						//alert(k + ':' + v)
						console.log(k, v)
					}
					try{
						this.$common.inAppWebviewCommunity('android', this.item_app_info.app_execu_code)

						if(this.item_app_info.app_execu_code && this.item_app_info.app_execu_code != 'AP00500001'){
							this.is_version = true
						}else{
							throw ''
						}
					}catch (e){
						this.certfc = result.data
						await this.$encodeStorage.setSessionCertfc(this.certfc)
						await this.$encodeStorage.setSessionVersion(token.app_version)
						await this.loginWith()
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)

				await this.$router.push({ name: 'mafia009', params: { type: 'third', msg: e}})
			}
		}
		, postUpdate: function(){
			try{
				this.$common.inAppWebviewCommunity('android', 'updateApp')
			}catch (e) {
				console.log(e)
			}
		}
		, toIdLogin: function(type){
			this.login_type = type
			this.loginWith()
		}
		, toMessageResult: function(e){
			this.loginWithSocial(e.data.data)
			this.gauth.close()
			window.removeEventListener('message', this.toMessageResult)
		}
		, toGAuth: function(){
			this.social_code = 'MB00400003'
			const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' +
				process.env.VUE_APP_CLIENTID +
				'&redirect_uri=https://' + window.location.host + '/auth/confirm' +
				'&response_type=token' +
				'&scope=email profile';

			this.gauth = window.open(url, 'gauth')

			window.addEventListener('message', this.toMessageResult)
		}
	}
	,async created() {
		console.log('auth login user', this.user)
		let at = this.$encodeStorage.getSessionAt()
		if(at){
			try {
				console.log('mafia011 exitApp')
				this.$common.inAppWebviewCommunity('android', 'exitApp')
			}catch (e) {
				console.log(e)
			}finally {
				console.log('to main')
				await this.$router.push({ name: 'main'})
			}
		}else{

			this.$emit('onLoad', this.program)

			if(this.$common.isApp()){
				this.isInit = true
				// this.is_update = true
			}else{
				/*
				let checkGauthLoad = setInterval(() => {
					console.log('checkGauthLoad')
					this.isInit = this.$gAuth.isInit;
					console.log(this.isInit)
					this.isSignIn = this.$gAuth.isAuthorized;
					console.log(this.isSignIn)
					if (this.isInit) clearInterval(checkGauthLoad);
				}, 1000);

				 */

			}
			console.log('this.is_auto', this.is_auto)
			if(this.is_auto){
				let login_info = this.$encodeStorage.getItem('login_info')
				console.log('login_info', login_info)
				if(login_info){

					this.onPin('pin_login')

					/*
					let e = {
						access_token: login_info.access_token
						, type: login_info.type
					}
					switch (login_info.type){
						case 'kakao':
							this.social_code = 'MB00400001'
							break
						case 'google':
							this.social_code = 'MB00400003'
							break
						case 'apple':
							this.social_code = 'MB00400002'
							break
						default:
							return false
					}
					await this.loginWithSocial(e)
					 */

				}else{
					return false
				}
			}else{
				this.$bus.$emit('getDeviceToken')
			}

			this.$bus.$on('pinCallback', (pin_type) => {
				switch (pin_type){
					case 'login':

						break
					case 'update':
						break
					case 'join':

						this.$bus.$off('pinCallback')
						this.$emit('to', { name: 'mafia0101'})
						break
				}
			})

			window.sign_in_social = (e) => {
				try{
					console.log(e.type)
					console.log(e.access_token)
					console.log(e.email)
					this.loginWithSocial(e)
				}catch (e) {
					console.log(e)
				}
			}

			window.fail_in_social = (e) => {
				console.log(e)
				this.$encodeStorage.removeItem('login_info')
				this.is_auto = false
				this.$router.push({ name: 'mafia009', params: { type: 'third', msg: this.$language.auth.login_fail_sns_error + ' ' + e.error }})
			}
		}
	}
	,watch: {

	}
}
</script>